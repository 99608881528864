import React from 'react'
import styled from '@emotion/styled'

const Section = styled.div`
    background-size: cover;

    color: #fff;
    position: relative;
    background: transparent
        linear-gradient(284deg, #00274d 0%, #00305f 57%, #002a5c 100%) 0% 0%
        no-repeat padding-box;
`

const BackgroundImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url(${props => props.image});
    opacity: 0.5;
`

const Content = styled.div`
    padding-left: 4em;
    padding-right: 2em;
    position: relative;
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 2rem;
    @media (min-width: 769px) {
        align-items: center;
        padding-bottom: 0;
    }
`

export default function Slide({
    children,
    backgroundImage,
    className,
    active = false,
}) {
    return (
        <Section
            className={[className, active && 'active', 'section'].join(' ')}
        >
            <BackgroundImage image={backgroundImage} />
            <Content>{children}</Content>
        </Section>
    )
}
