import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import styled from '@emotion/styled'

import Layout from '../layouts/layout'
import SEO from '../components/seo'

import Slides from '../components/Slides/Slides'
// import FactorySlide from '../components/page-components/products/ProductSlide/ProductSlide'
import Slide from '../components/page-components/products/ProductSlide/Slide'

import Slide01Bg from '../images/products/product-hero@2x.png'
import Slide02Bg from '../images/products/xilinx-hero@2x.png'
import Slide03Bg from '../images/products/portable-cores@2x.png'
import Slide04Bg from '../images/products/domain-specific-hero@2x.png'
import Button from '../components/Button/Button'

const ProductSlide = styled(Slide)`
    --font-base: 13px;
    font-size: calc(var(--font-base) + 6 * ((100vw - 320px) / 680));
    h2 {
        font-size: 3em;
    }
    h3 {
        font-size: 2.5em;
        font-weight: 400;
    }
    h2,
    h3 {
        text-transform: uppercase;
    }

    ul {
        font-size: 1.5em;
        line-height: 1.2;
    }

    @media (min-width: 769px) {
        --font-base: 16px;
    }
`

const SlideContent = styled.div`
    max-width: 50em;
`

const SlideWithImage = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
    div {
        flex: 1;

        &.gatsby-image-wrapper {
            display: none;
        }
    }
    @media (min-width: 640px) {
        div.gatsby-image-wrapper {
            display: inherit;
        }
    }
`

const SlideFooter = styled.div`
    @media (min-width: 769px) {
        position: absolute;
        bottom: 3rem;
    }
`

const Products = ({ location, data }) => {
    return (
        <Layout fixedHeader>
            <SEO
                title="RISC-V Tools"
                description="We help RISC-V the community evaluate, develop, accelerate and verify cores for optimal performance and reliability."
            />
            <Slides location={location}>
                <ProductSlide
                    backgroundImage={Slide01Bg}
                    active={location && location.hash === '#home'}
                >
                    <SlideWithImage>
                        <SlideContent>
                            <h2>OUR PRODUCTS</h2>
                            <h3>RISC-V FOR FPGA</h3>
                            <SlideFooter>
                                <Button
                                    css={{
                                        marginRight: '1rem',
                                        marginBottom: '1rem',
                                    }}
                                    to="/products#xilinx"
                                >
                                    XILINX ADAPTED CORES
                                </Button>
                                <Button
                                    css={{
                                        marginRight: '1rem',
                                        marginBottom: '1rem',
                                    }}
                                    to="/products#portable"
                                >
                                    PORTABLE CORES
                                </Button>
                                <Button to="/products#domain">
                                    DOMAIN SPECIFIC CORES
                                </Button>
                            </SlideFooter>
                        </SlideContent>
                        <Image fluid={data.slide1Image.childImageSharp.fluid} />
                    </SlideWithImage>
                </ProductSlide>
                <ProductSlide
                    backgroundImage={Slide02Bg}
                    active={location && location.hash === '#HW-Assisted'}
                >
                    <SlideContent>
                        <h2>
                            RISC-V <br />
                            HARDWARE-ASSISTED VERIFICATION
                        </h2>
                        <ul>
                            <li>High-speed verification platform ready to drop-in your RISC-V core</li>
                            <li>Supports core level (ISA) and system level (RTOS, Linux) testing</li>
                            <li>Verify standard ISA extensions, custom ISA extensions, and accelerators</li>
                            <li>Scalable anytime, anywhere access in the AWS cloud</li>
                        </ul>
                        <Button
                            to="https://info.bluespec.com/hav"
                            css={{
                                marginRight: '1rem',
                                marginBottom: '1rem',
                            }}
                        >
                            Learn More
                        </Button>
                    </SlideContent>
                </ProductSlide>
                <ProductSlide
                    backgroundImage={Slide03Bg}
                    active={location && location.hash === '#portable'}
                >
                    <SlideContent>
                        <h2>
                            Portable
                            <br />
                            RISC-V CORES
                        </h2>
                        <ul>
                            <li><a href="https://info.bluespec.com/Achronix">Achronix</a>, <a href="https://info.bluespec.com/xilinx">Xilinx</a>, <a href="https://info.bluespec.com/latticefpga">Lattice</a>, Microsemi</li>
                            <li>Linux and FreeRTOS support</li>
                            <li>Standard open source development tools</li>
                        </ul>
                        <Button to="/contact">Contact Us</Button>
                    </SlideContent>
                </ProductSlide>
                <ProductSlide
                    backgroundImage={Slide04Bg}
                    active={location && location.hash === '#domain'}
                >
                    <SlideContent>
                        <h2>
                            Domain-Specific
                            <br />
                            RISC-V CORES
                        </h2>
                        <ul>
                            <li>Accelerators packaged as software threads</li>
                            <li>Systematic hardware acceleration</li>
                            <li>Scalable concurrency</li>
                        </ul>
                        <Button to="/contact">Contact Us</Button>
                    </SlideContent>
                </ProductSlide>
            </Slides>
        </Layout>
    )
}

export default Products

export const query = graphql`
    query {
        slide1Image: file(
            relativePath: { eq: "products/product-chips2@2x.png" }
        ) {
            childImageSharp {
                fluid(maxHeight: 600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
