import React, { Component } from 'react'
import ReactFullpage from '@fullpage/react-fullpage'

import * as styles from './Slides.module.css'

class Slides extends Component {
    render() {
        const { children } = this.props

        const sectionsColor = []

        React.Children.forEach(children, element => {
            if (!React.isValidElement(element)) return
            let { theme = 'blue' } = element.props
            const themeNameTransformed = `var(--color-${theme
                .replace(/([a-zA-Z])(?=[A-Z])/g, '$1-')
                .toLowerCase()})`
            sectionsColor.push(themeNameTransformed)
        })

        const fpOptions = {
            sectionsColor,
            // paddingTop: 'var(--header-height)',
            navigation: true,
            navigationPosition: 'left',
            navigationTooltips: [
                '',
                'XILINX',
                'Portable Cores',
                'Domain-Specific Cores',
            ],
            // showActiveTooltip: true,
            slidesNavigation: true,
            verticalCentered: false,
        }

        return (
            <div className={styles.container}>
                <ReactFullpage
                    {...fpOptions}
                    render={({ state, fullpageApi }) => (
                        <ReactFullpage.Wrapper>
                            {children}
                        </ReactFullpage.Wrapper>
                    )}
                />
            </div>
        )
    }
}

export default Slides
